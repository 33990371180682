.error{
  color: #dc3545;
}

.attached-input {
  position: relative;
  text-align: center;
}

.attached-input input[type="file"] {
  display: block;
  margin: 10px auto;
}

.attached-input img.img-circle {
  width: 100%; /* Ensure the image fills the parent element */
  height: auto;
  max-width: 130px; /* Set a max width to ensure it fits within the circle */
  aspect-ratio: 1/1; /* Maintain a perfect square for circular cropping */
  object-fit: cover; /* Ensure the image covers the entire circular area */
  border-radius: 50%; /* Make the image circular */
  border: 3px solid #fff; /* Add a white border inside the blue circle */
  position: absolute; /* Position the image within the blue circle */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image within the parent */
}
